module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Eric Janto","short_name":"Eric Janto","description":"I write about computer science and other stuff.","start_url":"/","background_color":"white","theme_color":"#141414","display":"minimal-ui","icon":"static/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"bc50841a74caca036d724a557188b408"},
    },{
      plugin: require('../node_modules/gatsby-plugin-breadcrumb/gatsby-browser.js'),
      options: {"plugins":[],"defaultCrumb":{"location":{"pathname":"/"},"crumbLabel":"Eric Janto","crumbSeparator":" / "},"exclude":["**/writings/?search=**","**/dev-404-page/**","**/404/**","**/404.html","**/offline-plugin-app-shell-fallback/**"]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"classPrefix":"language-","inlineCodeMarker":null,"aliases":{},"showCaptions":["title"],"showLineNumbers":true,"noInlineHighlight":true,"prompt":{"user":"root","host":"localhost","global":true}},
    },{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"offsetY":30,"className":"anchor"},
    },{
      plugin: require('../node_modules/gatsby-remark-graph/gatsby-browser.js'),
      options: {"plugins":[],"language":"mermaid","theme":"default"},
    }]
